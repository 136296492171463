<template>
  <v-app-bar id="app-bar" absolute app color="#363636" flat height="89">
    <!-- <v-btn elevation="1" fab @click="setDrawer(!drawer)"> -->
    <!-- <v-icon v-if="drawer"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon> -->

    <v-icon v-if="drawer" square large @click="setDrawer(!drawer)" color="white">
      mdi-menu-open
    </v-icon>

    <v-icon v-else large square @click="setDrawer(!drawer)" color="white">
      mdi-menu
    </v-icon>
    <!-- </v-btn> -->

    <v-toolbar-title class="custom_title" v-text="$route.name" />

    <v-spacer />
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          color="white"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ notifications.length }}</span>
            </template>

            <v-icon large>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->
    <v-menu bottom right offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          color="white"
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
          @click="redirectToSupportTicket(openTickets)"
        >
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ openTickets.length }}</span>
            </template>

            <v-icon large>mdi-ticket</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <!-- <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(openTicket, i) in openTickets" :key="`item-${i}`">
            <app-bar-item-title
              width="100%"
              background-color="primary"
            >
              Ticket status open for order <b> {{ openTicket.order_id }} </b>
              for subject :
              <b>" {{ openTicket.subject_line }}"</b>
            </app-bar-item-title>
          </app-bar-item>
        </div>
      </v-list> -->
    </v-menu>

    <v-menu bottom right offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn color="white" class="ml-4" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ cancelOrders.length }}</span>
            </template>

            <v-icon large>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(cancelOrder, i) in cancelOrders" :key="`item-${i}`">
            <app-bar-item-title width="100%" background-color="primary">
              Order <b> {{ cancelOrder.orders[0].id }} </b> has been cancelled by driver
              due to
              <b>" {{ cancelOrder.reason }}"</b>
            </app-bar-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <!-- <template v-slot:activator="{ attrs, on }">
        <v-btn color="white" class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ vans.length }}</span>
            </template>

            <v-icon large>mdi-truck-delivery</v-icon>
          </v-badge>
        </v-btn>
      </template> -->

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(van, i) in vans" :key="`item-${i}`">
            <app-bar-item-title
              width="100%"
              @click="redirectToVanInventory(van)"
              background-color="primary"
            >
              Low Inventory in Van <b> {{ van.vehicle_number }} </b> at ZipCode
              <b> {{ van.zipcode }}</b>
            </app-bar-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-btn color="#e84500" @click="logout">
      <span>Sign Out</span>
      <v-icon right color="white">mdi-exit-to-app</v-icon>
    </v-btn>
    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-btn elevation="2" color="error" dark @click="logout">Logout</v-btn>
    </v-menu> -->
    <!--
    <audio autoplay id="audio" v-if="this.cancelorderlength > 0" >
      <source src="http://localhost:5000/fanfare.mp3" type="audio/mp3" />
    </audio> -->
  </v-app-bar>
</template>

<script>
import axios from "axios";
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    low_inventory_interval: null,
    notifications: [],
    vans: [],
    cancelOrders: [],
    cancelorderlength: 0,
    openTickets: [],
    // cancelorderlength: [],
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      sessionStorage.removeItem("user-token");
      sessionStorage.removeItem("user-permission");
      axios.post(process.env.VUE_APP_API_BASE_URL + "logout").then((response) => {
        if (response.status == 200) {
          this.$router.push({ name: "Login" });
        }
      });
    },
    getSupportTickets() {
      axios.get(process.env.VUE_APP_API_BASE_URL + "listingTicket").then((response) => {
        if (response.data.data) {
          const ticketslength = this.openTickets.length;
          response.data.data.forEach((element) => {
            if (
              this.openTickets.findIndex((el) => el.order_id == element.order_id) == -1
            ) {
              this.openTickets.push(element);
            }
          });
          if (this.openTickets.length > ticketslength) {
          }
        }
      });
    },
    getDriverCancelOrder() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "driverCancelOrder")
        .then((response) => {
          if (response.data.data) {
            this.cancelOrders = response.data.data;
          }
        });
    },

    getLowInventoryVans() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "vanLowInventoryAlert")
        .then((response) => {
          if (response.data.vans) {
            response.data.vans.forEach((element) => {
              if (
                this.vans.findIndex(
                  (el) => el.vehicle_number == element.vehicle_number
                ) == -1
              ) {
                this.vans.push(element);
              }
            });
          }
        });
    },
    redirectToVanInventory(van) {
      const params = { id: van.vehicle_id };
      if (this.$router.currentRoute.params != params) {
        this.$router.push({
          name: "View Van Product",
          params: params,
        });
      }
    },
    redirectToSupportTicket(openTickets) {
      const params = { id: openTickets._id };
      if (this.$router.currentRoute.params != params) {
        this.$router.push({
          name: "Tickets",
          params: params,
        });
      }
    },
  },
  created() {
    this.low_inventory_interval = setInterval(() => {
      this.cancelorderlength = 0;
      this.cancelOrders = [];
      this.getLowInventoryVans();
      this.getDriverCancelOrder();
    }, 20000);
  },
  beforeDestroy() {
    clearInterval(this.low_inventory_interval);
  },
  mounted() {
    this.getLowInventoryVans();
    this.getDriverCancelOrder();
    this.getSupportTickets();
  },
};
</script>
<style>
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.v-list-item {
  font-size: 11px;
}
</style>
